<template>

<div class="selection" :class="{'is-empty': !selection}" v-if="hasInfo">

	<div class="selection-empty" v-if="!selection">

		<div class="selection-empty-icon" v-on:click="onSelectClick"></div>

		<div class="selection-empty-title">{{ content.select.title }}</div>

		<div class="selection-empty-text">{{ content.select.text }}</div>

	</div>

	<div class="selection-product" v-if="selection">

		<div class="selection-product-about">

			<div class="selection-product-about-name"><small>{{ content.inhaler.title }}</small>{{ selection.name }}</div>
			<div class="selection-product-about-type"><span>{{ type.short }}</span></div>
			<div class="selection-product-about-edit"><router-link :to="{name: 'Recommendation.Select'}">{{ content.inhaler.edit }}</router-link></div>
			<div class="selection-product-about-image" :style="{backgroundImage: 'url(' + selection.image + ')'}" /> 

		</div>

		<div class="selection-product-guides">

			<div class="selection-product-guides-title">{{ content.inhaler.guides }}</div>

			<div class="selection-product-guides-links">

				<router-link :to="{name: 'Recommendation.Video'}" class="selection-product-guides-links-item is-video"><span></span>{{ content.inhaler.video }}<app-tick v-if="completed.video" /></router-link>
				<router-link :to="{name: 'Recommendation.Steps'}" class="selection-product-guides-links-item is-steps"><span></span>{{ content.inhaler.steps }}<app-tick v-if="completed.steps" /></router-link>

			</div>

		</div>

	</div>
	
</div>

</template>

<script>

export default {

	computed: {

		selection: function() {

			return this.$store.getters['selection']

		},

		completed: function() {

			return this.$store.getters['completed']

		},

		type: function() {

			return this.$_.findWhere(this.$store.getters['data'].inhalers.types, {
				id: this.selection.type
			})

		},

		content: function() {

			return this.$store.getters['data'].recommendations

		},

		hasInfo: function() {

			return this.$store.getters['recommendation'].info || this.$store.getters['selection']

		}

	},

	methods: {

		onSelectClick: function() {

			this.$router.push({
				name: 'Recommendation.Select'
			})

		}

	}

}

</script>

<style scoped>

.selection {
	background-color: #2A273E;
	width: 946px;
	margin: -102px auto auto auto;
	height: 204px;
	border-radius: 102px;
}

.is-mobile .selection {
	margin: 0px;
	border-radius: 0px;
	width: 100%;
	height: auto;
}

.selection.is-empty {
	display: flex;
	justify-content: center;
	align-items: center;
}

.selection-empty {
	padding: 20px 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.is-mobile .selection-empty {
	padding: 20px;
}

.selection-empty-icon {
	width: 42px;
	background-image: url(~@/assets/recommendation.add.svg);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	height: 42px;
	border-radius: 21px;
	background-color: #34D6F5;
	cursor: pointer;
	margin-bottom: 20px;
}

.selection-empty-title {
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1.8px;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.selection-empty-text {
	color: #fff;
	font-size: 18px;
	line-height: 22px;
}

.selection-product {
	display: flex;
	height: 204px;
	padding: 20px;
}

.is-mobile .selection-product {
	height: auto;
	flex-direction: column;
}

.selection-product-about {
	width: 600px;
	display: flex;
	flex-shrink: 1;
	flex-direction: column;
	justify-content: center;
	border-right: 1px solid rgba(255, 255, 255, 0.11);
	padding-left: 40px;
}

.is-mobile .selection-product-about {
	width: 100%;
	padding-left: 0px;
	border-right: 0px;
}

.selection-product-about-image {
	position: absolute;
	right: 0px;
	width: 200px;
	top: 0px;
	height: 160px;
	background-position: 100% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	margin-right: 20px;
}

.is-mobile .selection-product-about-image {
	position: relative;
	right: auto;
	top: auto;
	margin: 20px auto;
	background-position: 50% 50%;
}

.selection-product-about-name {
	color: #fff;
	text-transform: uppercase;
	font-weight: 300;
	padding-right: 220px;
	font-size: 30px;
	letter-spacing: 3.6px;
}

.is-mobile .selection-product-about-name {
	padding-right: 0px;
}

.selection-product-about-name small {
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 1.8px;
	display: block;
	margin-bottom: 5px;
}

.selection-product-about-type {
	margin-top: 5px;
}

.selection-product-about-type span {
	background-color: #58C145;
	border-radius: 15px;
	color: #2A273E;
	font-weight: 400;
	font-size: 18px;
	letter-spacing: 1.8px;
	display: inline-block;
	padding: 4px 10px;
}

.selection-product-about-edit {
	margin-top: 30px;
}

.is-mobile .selection-product-about-edit {
	margin: 0px;
	position: absolute;
	right: 0px;
	top: 0px;
}

.selection-product-about-edit a {
	font-size: 18px;
	font-weight: 500;
	color: #23D6F7;
	text-transform: uppercase;
	letter-spacing: 1.8px;
}

.is-mobile .selection-product-about-edit a {
	font-size: 14px;
}

.selection-product-guides {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	margin-right: 20px;
	align-items: center;
}

.is-mobile .selection-product-guides {
	margin: 0px;
}

.selection-product-guides-title {
	color: #fff;
	text-transform: uppercase;
	font-weight: 300;
	font-size: 18px;
	letter-spacing: 1.8px;
}

.selection-product-guides-links {
	display: flex;
	width: 100%;
}

.selection-product-guides-links-item {
	width: 50%;
	cursor: pointer;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-transform: uppercase;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 1.8px;
}

.selection-product-guides-links-item span {
	display: block;
	width: 100px;
	height: 100px;
	margin: 10px 0px;
	border-radius: 50%;
	background-color: #29374F;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.selection-product-guides-links-item.is-video span {
	background-image: url(~@/assets/recommendation.video.svg);
}

.selection-product-guides-links-item.is-steps span {
	background-image: url(~@/assets/recommendation.steps.svg);
}

.selection-product-guides-links-item >>> .tick {
	right: 20px;
	bottom: 30px;
}

</style>
